/**
 * Globals
 *
 */
export const $xl = $('#xl');
export const $xr = $('#xr');
export const $nav = $("#nav");
export const $navBg = $('#nav-bg');
export const $designsContainer = $("#designs-grid");
export const defaultDesignsSelected = "gallery";
export const designs = [
  {
    src: "./assets/images/4-Designs/Designs-Facades/1-Main-Contemporary-1.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/1-click-Contemporary-1.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/2-Main-Contemporary-2.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/2-click-Contemporary-2.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/3-Main-Contemporary-3.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/3-click-Contemporary-3.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/4-Main-Contemporary-4.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/4-click-Contemporary-4.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/5-Main-Grand-Modern.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/5-click-Grand-Modern.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Facades/6-Main-provincial.jpg",
    class: "design",
  },
  {
    src:
      "./assets/images/4-Designs/Designs-Facades/6-Click-Double-Provincial.jpg",
    class: "design",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/1-Main-external.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/1-click-external.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/2-Main-Kitchen.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/2-click-2-kitchen.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/2-click-kitchen.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/3-Main-Livings.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/3-click-2-living.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/3-click-living.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/4-Main-Bedrooms.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/4-click-2-bedrooms.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/4-click-bedrooms.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/5-Main-bathrooms.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/5-click-bathrooms.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/6-outdoor.jpg",
    class: "gallery",
  },
  {
    src: "./assets/images/4-Designs/Designs-Gallery/6-click-outdoor.jpg",
    class: "gallery",
  },
];
